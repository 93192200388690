.footer {
    a, a:visited, p, span {
        color: $white-ter; }
    a:hover {
        color: $turquoise;
        span {
            color: $turquoise; } }
    .social-link {
        margin-right: 1.5rem;
        text-decoration: none;
        color: $grey; } }
