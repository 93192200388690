body {
    background-image: url('../images/stars_background.jpg');
    background-repeat: repeat;
    background-size: contain; }

.navbar-item img {
    max-height: 80%; }

.container.flash {
    margin-top: 5rem; }

.slider {
    overflow: hidden;
    .slider-navigation-previous, .slider-navigation-next, .slider-pagination {
        display: none; } }

.brootle {
    box-shadow: inset 0 0 2px #fff,inset 1px 0 2px #27a,inset -1px 0 8px #3cf,inset 1px 0 3px #c3f,inset -1px 0 5px #3cf,0 0 12.5px #fff,-2.5px 0 10px #27a,2.5px 0 9px #0af;
    text-shadow: 0px 0px 6px black;

    .image img {
        border-radius: 1rem; } }
