.parallax-background {
    position: relative;
    .parallax-background-image {
        position: relative;
        top: 0;
        height: 100%;
        width: 100%; } }

.has-bg {
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat; }

.has-img-bg {
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat; }

