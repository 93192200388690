@import "../utilities/mixins";

.block {
  @extend %block; }

.delete {
  @extend %delete; }

.heading {
  display: block;
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase; }

.highlight {
  @extend %block;
  font-weight: $weight-normal;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  pre {
    overflow: auto;
    max-width: 100%; } }

.loader {
  @extend %loader; }

.number {
  align-items: center;
  background-color: $background;
  border-radius: $radius-rounded;
  display: inline-flex;
  font-size: $size-medium;
  height: 2em;
  justify-content: center;
  margin-right: 1.5rem;
  min-width: 2.5em;
  padding: 0.25rem 0.5rem;
  text-align: center;
  vertical-align: top; }
