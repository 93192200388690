$file-border-color: $border !default;
$file-radius: $radius !default;

$file-cta-background-color: $scheme-main-ter !default;
$file-cta-color: $text !default;
$file-cta-hover-color: $text-strong !default;
$file-cta-active-color: $text-strong !default;

$file-name-border-color: $border !default;
$file-name-border-style: solid !default;
$file-name-border-width: 1px 1px 1px 0 !default;
$file-name-max-width: 16em !default;

$file-colors: $form-colors !default;

.file {
  @extend %unselectable;
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  position: relative;
  // Colors
  @each $name, $pair in $file-colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      .file-cta {
        background-color: $color;
        border-color: transparent;
        color: $color-invert; }
      &:hover,
      &.is-hovered {
        .file-cta {
          background-color: bulmaDarken($color, 2.5%);
          border-color: transparent;
          color: $color-invert; } }
      &:focus,
      &.is-focused {
        .file-cta {
          border-color: transparent;
          box-shadow: 0 0 0.5em bulmaRgba($color, 0.25);
          color: $color-invert; } }
      &:active,
      &.is-active {
        .file-cta {
          background-color: bulmaDarken($color, 5%);
          border-color: transparent;
          color: $color-invert; } } } }
  // Sizes
  &.is-small {
    font-size: $size-small; }
  &.is-medium {
    font-size: $size-medium;
    .file-icon {
      .fa {
        font-size: 21px; } } }
  &.is-large {
    font-size: $size-large;
    .file-icon {
      .fa {
        font-size: 28px; } } }
  // Modifiers
  &.has-name {
    .file-cta {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0; }
    .file-name {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; }
    &.is-empty {
      .file-cta {
        border-radius: $file-radius; }
      .file-name {
        display: none; } } }
  &.is-boxed {
    .file-label {
      flex-direction: column; }
    .file-cta {
      flex-direction: column;
      height: auto;
      padding: 1em 3em; }
    .file-name {
      border-width: 0 1px 1px; }
    .file-icon {
      height: 1.5em;
      width: 1.5em;
      .fa {
        font-size: 21px; } }
    &.is-small {
      .file-icon .fa {
        font-size: 14px; } }
    &.is-medium {
      .file-icon .fa {
        font-size: 28px; } }
    &.is-large {
      .file-icon .fa {
        font-size: 35px; } }
    &.has-name {
      .file-cta {
        border-radius: $file-radius $file-radius 0 0; }
      .file-name {
        border-radius: 0 0 $file-radius $file-radius;
        border-width: 0 1px 1px; } } }
  &.is-centered {
    justify-content: center; }
  &.is-fullwidth {
    .file-label {
      width: 100%; }
    .file-name {
      flex-grow: 1;
      max-width: none; } }
  &.is-right {
    justify-content: flex-end;
    .file-cta {
      border-radius: 0 $file-radius $file-radius 0; }
    .file-name {
      border-radius: $file-radius 0 0 $file-radius;
      border-width: 1px 0 1px 1px;
      order: -1; } } }

.file-label {
  align-items: stretch;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  &:hover {
    .file-cta {
      background-color: bulmaDarken($file-cta-background-color, 2.5%);
      color: $file-cta-hover-color; }
    .file-name {
      border-color: bulmaDarken($file-name-border-color, 2.5%); } }
  &:active {
    .file-cta {
      background-color: bulmaDarken($file-cta-background-color, 5%);
      color: $file-cta-active-color; }
    .file-name {
      border-color: bulmaDarken($file-name-border-color, 5%); } } }

.file-input {
  height: 100%;
  left: 0;
  opacity: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%; }

.file-cta,
.file-name {
  @extend %control;
  border-color: $file-border-color;
  border-radius: $file-radius;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap; }

.file-cta {
  background-color: $file-cta-background-color;
  color: $file-cta-color; }

.file-name {
  border-color: $file-name-border-color;
  border-style: $file-name-border-style;
  border-width: $file-name-border-width;
  display: block;
  max-width: $file-name-max-width;
  overflow: hidden;
  text-align: inherit;
  text-overflow: ellipsis; }

.file-icon {
  align-items: center;
  display: flex;
  height: 1em;
  justify-content: center;
  @include ltr-property("margin", 0.5em);
  width: 1em;
  .fa {
    font-size: 14px; } }
