@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,700&display=swap');

$black:        #050505;
$black-bis:    #141414;
$black-ter:    #282828;

$grey-darker:  #2a2a2a;
$grey-dark:    #4a4a4a;
$grey:         #6a6a6a;
$grey-light:   #8a8a8a;
$grey-lighter: #aaaaaa;

$white-ter:    #acacac;
$white-bis:    #cccccc;
$white:        #f9f9f9;

$orange:       #ff8552;
$yellow:       #f3a712;
$green:        #739948;
$turquoise:    #3fcae8;
$cyan:         #2274a5;
$blue:         #29335c;
$purple:       #5f5aa2;
$red:          #960200;

$family-sans-serif: "Open Sans", sans-serif;
$family-secondary: 'Open Sans', sans-serif;

$radius-small: 2px;
$radius: 2px;
$radius-large: 4px;

$info: $blue;
$success: $green;
$warning: $orange;
$danger: $red;

$light: $white;
$dark: $black;

$border: $white;
$border-hover: $blue;

$text: $white;
$text-light: $white;
$text-strong: $white;
$background: $black;
$body-background-color: $black;

$link: $cyan;
$link-invert: $cyan;
$link-visited: $cyan;

$link-hover: lighten($cyan, 20%);
$link-hover-border: lighten($cyan, 20%);

$link-focus: lighten($cyan, 20%);
$link-focus-border: lighten($cyan, 20%);

$link-active: $cyan;
$link-active-border: $cyan;


$footer-background-color: $black-bis;
$footer-padding: 3rem 0rem;
$box-color: $white;
$box-background-color: rgba($black, 0);
$card-radius: 1rem;
$card-background-color: rgba($black, 0.4);
$modal-card-body-background-color: $black-bis;

$input-color: $black;
$input-background-color: $white-bis;

@import "_footer.sass";
@import "_hr.sass";
@import "_table.sass";
@import "_box.sass";
@import "_navbar.sass";

@import "../../sass/bulma/bulma.sass";
@import "../../sass/bulma/extensions/bulma-switch-2.0.4.sass";

@import "_custom.sass";
@import "_backgrounds.sass";
