$navbar-background-color: #000012cc;
$navbar-height: 5rem;

$navbar-item-color: $white;
$navbar-item-hover-color: $turquoise;
$navbar-item-hover-background-color: transparent;

$navbar-item-active-color: $turquoise;
$navbar-item-active-background-color: transparent;
$navbar-item-img-max-height: 5rem;

$navbar-tab-hover-background-color: transparent;
$navbar-tab-hover-border-bottom-color: transparent;

$navbar-tab-active-color: $turquoise;
$navbar-tab-active-background-color: transparent;
$navbar-tab-active-border-bottom-color: transparent;
$navbar-tab-active-border-bottom-style: none;
$navbar-tab-active-border-bottom-width: 0;
$navbar-dropdown-background-color: $black;
$navbar-dropdown-border-top: $white-ter;
$navbar-dropdown-arrow: $white-ter;

$navbar-dropdown-item-hover-color: $turquoise;
$navbar-dropdown-item-hover-background-color: $black;
$navbar-dropdown-item-active-color: $turquoise;
$navbar-dropdown-item-active-background-color: $black;

$navbar-divider-background-color: $black;
$navbar-divider-height: 2px;
$navbar-bottom-box-shadow-size: 4px;

.navbar {
    border-bottom: solid 1px $white-ter; }

.navbar-item, .navbar-link {
    text-transform: uppercase;
    font-size: 0.85rem;
    text-align: right; }

.navbar-menu.is-active {
    .navbar-end {
        background-color: $black; } }

.navbar-burger span {
    height: 2px !important; }
